<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/study`"
        />

        <div class="studyCommentSection pageContent">
            <div class="studyCommentSection__content">
                <div id="hypercomments_widget" class="hypercomments_widget"></div>
                <a href="http://hypercomments.com" class="hc-link" title="comments widget"
                    >comments powered by HyperComments</a
                >
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
export default {
    name: "studyCommentSection",

    components: {
        Layout,
        PageHeader,
    },

    beforeCreate() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.chat &&
                (this.$store.getters.getMember.data.memberAccessLevel === "ВИП 3.0" ||
                    this.$store.getters.getMember.data.memberAccessLevel === "VIP")
            )
        ) {
            this.$router.push(`/${this.$store.getters.getMember.data.slug}/study`);
        }
    },

    mounted() {
        // hypercomments script
        let HCScript = document.createElement("script");
        HCScript.type = "text/javascript";
        let code =
            "var _hcwp = window._hcwp || [];\n" +
            '        _hcwp.push({widget:"Stream", widget_id: 92621, href: window.location.href});\n' +
            "        (function() {\n" +
            '            if("HC_LOAD_INIT" in window)return;\n' +
            "            var HC_LOAD_INIT = true;\n" +
            '            var lang = (navigator.language || navigator.systemLanguage || navigator.userLanguage || "en").substr(0, 2).toLowerCase();\n' +
            '            var hcc = document.createElement("script"); hcc.type = "text/javascript"; hcc.async = true;\n' +
            '            hcc.src = ("https:" == document.location.protocol ? "https" : "http")+"://w.hypercomments.com/widget/hc/92621/"+lang+"/widget.js";\n' +
            '            var s = document.getElementsByTagName("script")[0];\n' +
            "            s.parentNode.insertBefore(hcc, s.nextSibling);\n" +
            "        })();";
        try {
            HCScript.appendChild(document.createTextNode(code));
            document.body.appendChild(HCScript);
        } catch (e) {
            HCScript.text = code;
            document.body.appendChild(HCScript);
        }
    },

    data() {
        return {
            title: `Комментарии: Блок ${this.$route.params.block_priority}`,
            items: [
                {
                    text: "Обучение",
                    to: `/${this.$route.params.slug}/study`,
                },
                {
                    text: `Комментарии: Блок ${this.$route.params.block_priority}`,
                    active: true,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "./studyCommentSection.scss";
</style>
